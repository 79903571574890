/* You can add global styles to this file, and also import other style files */

@use "boostrap";
@use "layout";
@use "snackbar";

body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


//.app-wrapper {
//  height: 100%;
//  width: 100%;
//  overflow-x: hidden;
//  margin: 0 auto;
//
//}
