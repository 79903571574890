//@import "variables";
//@import "mixins";

.app-wrapper {
  height: 100%;
  width: 100%;
  //font-size: $font-size-text;
  overflow-x: hidden;
  margin: 0 auto;
  //color: $test-blue;
}

.row {
  margin-top: 0.7rem;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
}

