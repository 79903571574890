.success-snackbar {
  &.mat-mdc-snack-bar-container {
    & .mdc-snackbar__label {
      color: #27933e !important;
    }
  }

  --mdc-snackbar-container-color: #d4edda;
}

.error-snackbar {
  &.mat-mdc-snack-bar-container {
    & .mdc-snackbar__label {
      color: #202025 !important;
    }

    & .mdc-button__label {
      color: #202025 !important;
    }
  }

  --mdc-snackbar-container-color: rgba(232, 145, 145, 0.94);
}
